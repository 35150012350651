<template>
    <section>
      <loading v-bind:dialog="isLoading"></loading>

      <!-- Esto es el buscador deberia ir separado -->
      <v-form ref="form" v-on:submit.prevent="Buscar">
        <v-layout row wrap>
          <v-flex xs8 sm10 lg10>
            <v-text-field
              placeholder="Ingrese el nombre del cliente a buscar"
              v-model="search"
              :rules="searchRules"
              autofocus
            ></v-text-field>
          </v-flex>
          <v-flex xs4 sm2 lg2>
            <v-btn type="submit" color="black" outline block :disabled="isLoading">
              <v-icon>search</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>

      <v-layout row wrap>
        <v-flex lg12>
          <alert v-bind:tipo="notificacion.tipo" v-bind:mensaje="notificacion.mensaje"></alert>
          <v-spacer></v-spacer>
          <v-card v-show="!isLoading && notificacion.mensaje === ''">
            <v-toolbar card color="white">
              <v-text-field flat solo disabled label="Listado de clientes" single-line hide-details></v-text-field>
              <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
                <v-btn icon slot="activator">
                  <v-icon>more_vert</v-icon>
                </v-btn>

                <v-list>
                  <v-list-tile
                    v-for="(item) in headers"
                    :key="item.value"
                    @click="changeSort(item.value)"
                  >
                    <v-list-tile-title>
                      {{ item.text }}
                      <v-icon
                        v-if="pagination.sortBy === item.value"
                      >{{pagination.descending? 'arrow_downward':'arrow_upward'}}</v-icon>
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
                
              </v-menu>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout v-resize="onResize" column wrap>
                <v-data-table
                  :headers="headers"
                  :items="clientes"
                  :pagination.sync="pagination"
                  :hide-headers="isMobile"
                  :class="{mobile: isMobile}"
                  :rows-per-page-items="rowsperpageitems"
                >
                  <template slot="items" slot-scope="props">
                    <tr v-if="!isMobile">
                      <td>{{ props.item.cli_razsoc }}</td>
                      <td>{{ props.item.cli_telefo }}</td>
                      <td>{{ props.item.cli_email }}</td>
                      <td class="notbreak">
                        <v-btn 
                          prepend-icon="mdi-vuetify"
                          v-bind:color="getcolor(props.item.cli_codigo)"
                          @click="SelectCliente(props.item,'Historico')"
                          title="Ver historico"
                        >
                          <v-icon> history </v-icon>
                        </v-btn>
                    
                      </td>
                    </tr>
                    <tr v-else>
                      <td>
                        <ul class="flex-content">
                          <li class="flex-item" data-label="Nombre">{{ props.item.cli_razsoc }}</li>
                          <li class="flex-item" data-label="Telefóno">{{ props.item.cli_telefo }}</li>
                          <li class="flex-item" data-label="E-mail">{{ props.item.cli_email }}</li>
                          <li class="flex-item" data-label="Acciones">
                            <v-btn 
                              prepend-icon="mdi-vuetify"
                              v-bind:color="getcolor(props.item.cli_codigo)"
                              @click="SelectCliente(props.item,'Historico')"
                              title="Ver histórico"
                              >
                            <v-icon> history </v-icon>
                            </v-btn>
                          </li>
                          <li class="enter"></li>
                        </ul>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </section>
  </template>
  
  <script>
  import DataTableMixin from "@/mixins/DataTableMixin";
  import clienteservice from "@/services/ClienteServices";
  import alert from "@/components/alert/alert.vue";
  import loading from "@/components/share/Loading.vue";
  export default {
    name: "MostrarCliente",
    mixins: [DataTableMixin],
    components: { alert,loading },
    data() {
      return {
        color: "secondary",
        pagination: { sortBy: "cli_razsoc" },
        search: "",
        isMobile: false,
        headers: [
          { text: "Razón social", value: "cli_razsoc" },
          { text: "Telefóno", value: "cli_telefo" },
          { text: "E-mail", value: "cli_email" },
          { text: "Acciones", value: "" }
        ],
        notificacion: { mensaje: "", tipo: "error" },
        clientes: [],
        isCliente: clienteservice.current(),
        isLoading: false,
        searchRules: [
          v => !!v || "Debe ingresar un cliente.",
          v => v.length >= 3 || "Ingrese un cliente con 3 o más caracteres."
        ]
      };
    },
    methods: {
      Buscar() {
        if (this.$refs.form.validate()) {
          this.isLoading = true;
          this.clientes = [];
          clienteservice
            .getAll()
            .then(res => {
              //Cargamos solo los elementos que cumplan
              //con las condiciones de filtrado
              this.clientes = res.filter((element) => {
                var aux = "";
                aux = element.cli_razsoc.toLocaleLowerCase();
                if (aux.includes(this.search.toLocaleLowerCase())) {
                  return element;
                }
              });
            })
            .catch(err => {
              this.notificacion.mensaje =
                "Ocurrio un error al intentar recuperar los registros.";
              if (err.response && err.response.data.hasOwnProperty('errorMessage')) {
                this.notificacion.mensaje = err.response.data.errorMessage;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      SelectCliente(cliente, ruta) {
        this.$router.push({name: ruta, params:{id: cliente.cli_codigo}});
      },
      getcolor(codcli) {
        return codcli !== this.isCliente.cli_codigo ? "secondary" : "success";
      }
    }
  };
  </script>