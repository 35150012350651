<template>
      <v-container grid-list-xl fluid>
          <div v-if="IsAdmin">
              <clienteBuscar />
          </div>
      </v-container>
</template>


<script>
import clienteBuscar from "@/components/cliente/MostrarClientes.vue";
import clienteService from "@/services/ClienteServices";
import userService from "@/services/UserServices";

export default {
    data(){
        return{
            cliente:{}
        }
    },
    components: {
        clienteBuscar
    },
    computed: {
        IsAdmin() {
            return userService.IsAdmin();
        }
    },
    created() {
        this.cliente = clienteService.current();
        this.$bus.$on('SelectorClienteSetCliente', () => {
            this.cliente = clienteService.current();
        });
    }
    
}
</script>


<style scoped>
    
</style>